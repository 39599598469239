import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";

import marker from './marker.png';  // Импорт изображений
import logo from './logo.jpg';  // Импорт изображений

import * as api from "../../utils/api.js"

const tg = window.Telegram.WebApp;

const Wheel = (name) => {

  const [wheelData, setWheelData] = useState(null);
  // const [wheelUserPrize, setWheelUserPrize] = useState(null);
  const [prize, setPrize] = useState("-");
  const [sectors, setSectors] = useState({})

  const [isLoading, setLoading] = useState(true)
  const [hasSpun, setHasSpun] = useState(false);
  const [showDisplay, setShowDisplay] = useState(false); // Для отображения приза

  const zoneSize = 360 / Object.keys(sectors).length; // Размер зоны в градусах
  
  useEffect(() => {
    const fetchWheelData = async () => {
      const token = localStorage.getItem("token");
      const wheel = await api.WheelGet(name, token);
      const prizes = await api.prizesGet(wheel.id, token)

      const prizesList = {}
      let i = 0
      for (const prize of prizes) {
        prizesList[prize.id] = {'name': prize.name, 'number': i}
        i += 1
      }

      console.log(prizesList)

      const wheelUserSpin = await api.getWheelUserSpin(413, wheel.id, token)
      // const wheelUserSpin = await api.getWheelUserSpin(837143843, wheel.id, token)
      if (wheelUserSpin) {
        console.log(wheelUserSpin)
        setPrize(prizesList[wheelUserSpin.prize_id]['name'])
        setShowDisplay(true); // Показываем приз
        setHasSpun(true)
      } else {
        console.log(4)
      }
      setSectors(prizesList)

      setWheelData(wheel);
      setLoading(false); // Data has been fetched
      
    };
    
    fetchWheelData();
  }, [name]); // Run when name changes


const [deg, setDeg] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const canvasRef = useRef(null);

  const param = useParams();
  
  const drawWheel = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const sectorIds = Object.keys(sectors);
    const numSectors = sectorIds.length;
    const sectorAngle = 2 * Math.PI / numSectors;
    const radius = canvas.width / 2;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    let i = 0
    const colors = [`hsl(6, 70%, 50%)`, `hsl(6, 100%, 100%)`]
    const textColors = ["#ffffff", '#0f0200']

    sectorIds.forEach((sectorId, index) => {
      const startAngle = index * sectorAngle - Math.PI / 2; // Start at 12 o'clock
      const endAngle = startAngle + sectorAngle;

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      ctx.closePath();


      // Установка градиента для каждого сектора
      const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius);

      // Выбор градиента: черный-белый или желтый-красный в зависимости от индекса
      if (index % 2 === 0) {
        gradient.addColorStop(0.8, 'white');
        gradient.addColorStop(1, 'grey');
      } else {
        gradient.addColorStop(0.6, '#9f1402');
        gradient.addColorStop(1, 'red');
      }

      ctx.fillStyle = gradient; // Установка градиента как заливку
      ctx.fill();
      ctx.stroke();
      i ++;

      // Draw sector text
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(startAngle + sectorAngle / 2);
      ctx.textAlign = 'right';
      ctx.fillStyle = textColors[i%2];
      ctx.font = 'bold 25px "Bookman Old Style"';
      ctx.fillText(sectors[sectorId]['name'], radius - 40, 7);
      ctx.restore();
    });

      //  // Добавляем желтый ободок с лампочками
      //  const lampRadius = radius - 10;
      //  // Радиус лампочек
      //  const numLamps = 24;
      //  // Количество лампочек
      //  const lampAngle = 2 * Math.PI / numLamps;

      //  ctx.beginPath();
      //  ctx.arc(centerX, centerY, lampRadius, 0, 2 * Math.PI); // Ободок
      //  ctx.strokeStyle = 'yellow';
      //  ctx.lineWidth = 10;
      //  ctx.stroke();
   
      //  for (let j = 0; j < numLamps; j++) {
      //    const lampX = centerX + lampRadius * Math.cos(j * lampAngle);
      //    const lampY = centerY + lampRadius * Math.sin(j * lampAngle);
   
      //    ctx.beginPath();
      //    ctx.arc(lampX, lampY, 5, 0, 2 * Math.PI); // Лампочка
      //    ctx.fillStyle = 'yellow';
      //    ctx.fill();
      //    ctx.strokeStyle = 'orange';
      //    ctx.stroke();
      //  }

    // Draw arrow
    ctx.beginPath();
    ctx.moveTo(centerX - 10, centerY - radius - 20);
    ctx.lineTo(centerX + 10, centerY - radius - 20);
    ctx.lineTo(centerX, centerY - radius - 40);
    ctx.closePath();
    ctx.fillStyle = 'red';
    ctx.fill();
  };


// Функция для получения случайного приза
const fetchPrizeRandom = async () => {
  const token = localStorage.getItem("token");
  const randomPrize = await api.getPrizeRandom(wheelData.id, token);
  return randomPrize; // Возвращаем значение
};

  
  const handleSpin = async () => {
    if (isSpinning) return;

    setPrize("-");
    setIsSpinning(true);
    setHasSpun(true); 

    const selectedPrize = await fetchPrizeRandom();

    console.log('prizze')
    console.log(selectedPrize)
    console.log(sectors[selectedPrize])

    const prize_deg = sectors[selectedPrize]['number'] * zoneSize + Math.floor(Math.random() * zoneSize)
    console.log(prize_deg)

    const full_spins = (Math.floor(Math.random() * 5) + 20) * 360
    const newDeg = -prize_deg + full_spins
    setDeg(newDeg);
    console.log(sectors)
    const canvas = canvasRef.current;
    if (canvas) {
      // Устанавливаем CSS для плавного вращения
      canvas.style.transition = 'all 10s ease-out';
      canvas.style.transform = `rotate(${newDeg}deg)`;
      canvas.classList.add('blur');
    }
  };

  const handleTransitionEnd = () => {
    setIsSpinning(false);
    setShowDisplay(true); // Показываем приз

    const canvas = canvasRef.current;
    if (canvas) {
      canvas.classList.remove('blur');
      canvas.style.transition = 'none';

      // Вычисляем фактический угол после вращения
      const actualDeg = deg % 360;

      // Определяем приз
      const sectorIndex = Math.floor((360 - actualDeg) / zoneSize) % Object.keys(sectors).length;
      const sectorIds = Object.keys(sectors);
      setPrize(sectors[sectorIds[sectorIndex]]['name']);

      api.spinAddItem(tg.initDataUnsafe.user.id, wheelData.id, sectorIds[sectorIndex]['name'])
      api.sendPrizeInfo(tg.initDataUnsafe.user, sectorIds[sectorIndex]['name'])

    }
  };

  useEffect(() => {
    
    if (!isLoading) {
      if (wheelData.is_open === true)
        setTimeout(() => {
          drawWheel();
        }, 5);
    }
  }, [isLoading]);

  useEffect(() => {
    // Обновляем изображение колеса при изменении угла
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.style.transform = `rotate(${deg}deg)`;
    }
  }, [deg]);
  








    const [activeRange, setActiveRange] = useState(0);
    const [lightsOn, setLightsOn] = useState(true);

    useEffect(() => {
        // Update the active range every 1.5 seconds to create a moving effect
        const intervalId = setInterval(() => {
            setActiveRange(prev => (prev + 1) % 16); // Move the range in a circular manner
        }, 250); // Adjust the interval time as needed

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);

    useEffect(() => {
      // Устанавливаем интервал для переключения состояния лампочек
      const intervalId = setInterval(() => {
          setLightsOn(prevState => !prevState); // Меняем состояние на противоположное (вкл/выкл)
      }, 500); // Интервал в 1000 миллисекунд (1 секунда) — можно изменить
  
      return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
  }, []);

    const lightStyle = (index) => {
        // Determine if the current light is within the active range
        const isActive = (index >= activeRange && index < activeRange + 10) || (index + 16 >= activeRange && index + 16 < activeRange + 10);
        
        return {
            position: 'absolute',
            top: `${50 - 42 * Math.cos((index * 22.5) * Math.PI / 180)}%`,
            left: `${50 + 42 * Math.sin((index * 22.5) * Math.PI / 180)}%`,
            width: '10px',
            height: '10px',
            background: 'radial-gradient(circle, #ffeb3b 40%, #ffca28 70%, #f57f17 100%)',
            borderRadius: '50%',
            boxShadow: isActive ? `
                0 0 10px 4px rgba(255, 235, 59, 0.6),
                inset 0 0 7px rgba(255, 193, 7, 0.8),
                0 0 3px rgba(0, 0, 0, 0.5)
            ` : 'none',
            transform: `translate(-50%, -50%)`,
            opacity: isActive ? 1 : 0.3,
            transition: 'opacity 0.2s ease-in-out', // Smooth transition
        };
    };

    const lightSpinButtonStyle = (index) => {
      const width = 240; // Ширина блока
      const height = 100; // Высота блока
      const radiusX = width / 2; // Полу-ширина блока
      const radiusY = height / 2; // Полу-высота блока
      const cornerRadius = 30; // Радиус закругленных углов
    
      let x = 0, y = 0;
    
      if (index < 7) {
        // Верхняя сторона (4 лампочки)
        const progress = index / 3;
        x = -radiusX + progress * (0.7 * radiusX) + 35; 
        y = -radiusY + 6;
      } else if (index >= 7 && index < 14) {
        // Нижняя сторона (4 лампочки)
        const progress = (index - 4) / 3;
        x = -radiusX + progress * (0.7 * radiusX) - 45;
        y = radiusY - 6;
      } else if (index === 14) {
        // Левая верхняя лампочка (угол)
        x = -radiusX + cornerRadius / 2;
        y = -radiusY + cornerRadius / 2;
      } else if (index === 15) {
        // Левая нижняя лампочка (угол)
        x = -radiusX + cornerRadius / 2;
        y = radiusY - cornerRadius / 2;
      } else if (index === 16) {
        // Правая верхняя лампочка (угол)
        x = radiusX - cornerRadius / 2;
        y = -radiusY + cornerRadius / 2;
      } else if (index === 17) {
        // Правая нижняя лампочка (угол)
        x = radiusX - cornerRadius / 2;
        y = radiusY - cornerRadius / 2;
      } else if (index === 18) {
        // Левая сторона (2 лампочки) — ближе к центру
        y = -radiusY / 2 + 12; // Лампочка ближе к центру сверху
        x = -radiusX + 10;
      } else if (index === 19) {
        // Левая сторона (2 лампочки) — ближе к центру
        y = radiusY / 2 - 12; // Лампочка ближе к центру снизу
        x = -radiusX + 10;
      } else if (index === 20) {
        // Правая сторона (2 лампочки) — ближе к центру
        y = -radiusY / 2 + 12; // Лампочка ближе к центру сверху
        x = radiusX - 10;
      } else if (index === 21) {
        // Правая сторона (2 лампочки) — ближе к центру
        y = radiusY / 2 - 12; // Лампочка ближе к центру снизу
        x = radiusX - 10;
      }

      return {
        position: 'absolute',
        top: `${50 + (y / height) * 100}%`, // Преобразуем координаты в проценты
        left: `${50 + (x / width) * 100}%`,
        width: '10px',
        height: '10px',
        background: 'radial-gradient(circle, #ffeb3b 40%, #ffca28 70%, #f57f17 100%)',
        borderRadius: '50%',
        boxShadow: lightsOn ? `
          0 0 10px 4px rgba(255, 235, 59, 0.6),
          inset 0 0 7px rgba(255, 193, 7, 0.8),
          0 0 3px rgba(0, 0, 0, 0.5)
        ` : 'none',
        opacity: lightsOn ? 1 : 0.3, // Blink effect: fully visible if active, dimmed if inactive
        transform: 'translate(-50%, -50%)',
        transition: 'opacity 0.2s ease-in-out',
      };
    };
    
    
  return (
    <div id="name">
      {
        isLoading ? (
          <div><h1>LOAD</h1></div>
        ) : (
          wheelData.is_open === true ? (
            <div className="wheelContainer">
              <img className="marker" src={marker} alt="Marker" />

              {/* <div className='centerLogo'><img src={logo} alt="" /></div> */}
              {/* <div className='centerLogo'></div> */}

              <div className='lightContainer'>
                  {Array.from({ length: 16 }).map((_, i) => (
                      <div key={i} style={lightStyle(i)}></div>
                  ))}
              </div>
              
              <div className="borderLine"></div>

              <div className="wheel-center">
                <div className="wheel-border"></div>
                <div className="center-circle">
                  <div className="center-glow"></div>
                  <div className="center-reflection"></div>
                  <div className="center-highlight"></div>
                  <div className="inner-shadow"></div>
                </div>
              </div>
              
              <canvas
                className={`wheel ${isSpinning ? 'blur' : ''}`}
                ref={canvasRef}
                width={400}
                height={400}
                onTransitionEnd={handleTransitionEnd}
              />
              

              
              {!hasSpun && (
                <div className="container">
                  <div className="spinButtonBack">
                    <div className='lightSpinButtonContainer'>
                      {Array.from({ length: 22 }).map((_, i) => (
                        <div key={i} style={lightSpinButtonStyle(i)}></div>
                      ))}
                    </div>
                  </div>
                  <button className="spin-button" onClick={handleSpin}>
                    <span className="button-text">START</span>
                  </button>
                </div>
              )}

              {showDisplay && (
                <div className="container">

                <div className="PrizeButtonBack">
                <div className='lightSpinButtonContainer'>
                      {Array.from({ length: 22 }).map((_, i) => (
                        <div key={i} style={lightSpinButtonStyle(i)}></div>
                      ))}
                    </div>
                      <div className="display">
                        {/* <span>Вам выпало:</span> */}
                        <span className="prize-text">{prize}</span>
                    </div>
                </div>

                </div>

              )} {/* Показываем приз, когда он определён */}

            </div>
          ) : (
            <div className='close-message'><h1>Сейчас колесо закрыто</h1></div>
          )
        )
      }
    </div>
  );
  
};

export default Wheel;
